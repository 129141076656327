<template>
  <div>
    <div class="d-flex justify-content-end mt-4 px-0">
      <b-dropdown
        :text="$t('ADD')"
        class="button-pre-style mb-2"
        variant="primary"
        right
      >
        <b-dropdown-item
          @click="addRow('Product')"
          :disabled="isProductDisabled"
        >
          {{ $t("PRODUIT") }}</b-dropdown-item
        >
        <b-dropdown-item @click="addRow('Forfait')">{{
          $t("FORFAIT")
        }}</b-dropdown-item>
        <b-dropdown-item @click="addRow('Main d\'oeuvre')">{{
          $t("MAIN_D_OEUVRE")
        }}</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="d-flex justify-content-center">
      <v-container v-if="progress" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
      <div v-else class="border">
        <div class="scroll-container">
          <div class="content-wrapper">
            <!--header-->
            <div class="header d-flex mb-3 px-3 pt-2">
              <div class="header-item" style="flex: 1.5">
                {{ $t("PRODUIT") }}
              </div>
              <div class="header-item" style="flex: 1">
                {{ $t("REF") }}
              </div>
              <div class="header-item" style="flex: 3">
                {{ $t("WORDING") }}
              </div>
              <div class="header-item" style="flex: 1">
                {{ $t("QTE") }}
              </div>
              <div class="header-item" style="flex: 1.5">
                {{ $t("type_uniteU") }}
              </div>
              <div class="header-item" style="flex: 1.5">
                {{ $t("PUHT") }}
              </div>
              <div class="header-item" style="flex: 1">
                {{ $t("TOTALHT") }}
              </div>
              <div
                class="header-item"
                style="flex: 1; border-radius: 0 5px 0 0"
              >
                {{ $t("VAT") }}
              </div>
            </div>
            <!--section vide-->
            <div
              v-if="
                getdevisMonoFiche.data.GroupeLigneDocument_produit.length === 0
              "
              class="text-center my-4"
            >
              {{ $t("NO_DATA_FOUND") }}
            </div>
            <!--section des produits-->
            <div v-else>
              <div
                v-for="(row, index) in getdevisMonoFiche.data
                  .GroupeLigneDocument_produit"
                :key="index"
                class="row-item d-flex"
              >
            
                <form @submit.prevent="handleSubmit">
                  <div class="row m-2">
                    <div class="input-wrapper mx-2" style="flex: 2">
               
                      <!-- <multiselect
                      v-if="row.type === 'Product' && shouldDisplaySearchInput(row)"
                      :options="getAllProducts"
                      label="lib"
                      placeholder="$t('PRODUIT')"
                      :searchable="true"
                      :multiple="true"
                      :close-on-select="false"
                      :show-labels="true"
                      @select="onSelect"
                      @remove="onRemove"
                      :allow-empty="true"
                      :taggable="true"
                      class="truncated-label"
                    ></multiselect> -->
                       <searchInput
                        v-if="
                          row.type === 'Product' &&
                          shouldDisplaySearchInput(row) "
                        :list="getAllProducts"
                        label="lib"
                        :placeholder="$t('PRODUIT')"
                        :searchFunc="all_products"
                        @searchfilter="addProduit($event, index)"
                        :filtre="{ entreprise: form.id }"
                        @change="limitDisplay"
                        :formatter="formatSelectedItems"
                        class="truncated-label"
                      ></searchInput> 
                      <searchInput
                        v-if="row.type === 'Forfait'"
                        :list="getAllforfaits"
                      
                        label="lib"
                        :placeholder="$t('FORFAIT')"
                        :searchFunc="all_forfaits"
                        @searchfilter="addProduit($event, index)"
                        :filtre="{ entreprise: form.id }"
                      ></searchInput>
                  
                      <searchInput
                        v-if="row.type === 'Main d\'oeuvre'"
                        :list="getAllmainoeuvres"
                        
                        label="lib"
                        :placeholder="$t('MAIN_')"
                        :searchFunc="all_mainoeuvres"
                        @searchfilter="addProduit($event, index)"
                        :filtre="{ entreprise: form.id }"
                      ></searchInput>
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1">
                      <b-form-input
                        v-model="row.ref"
                        maxlength="10"
                       
                        class="custom-input"
                      />
                    </div>
                    <div class="input-wrapper ms-2" style="flex: 3">
                      <b-form-input
                        class="custom-input"
                        v-model="row.lib"
                        @input="row.lib = $event.toUpperCase()"
                        required
                        maxlength="100"
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1">
                      <b-form-input
                        class="custom-input"
                        type="number"
                        v-model.number="row.quantite"
                        min="1"
                        required
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1.5">
                      <b-form-select
                        class="custom-input"
                        v-model="row.type_unite"
                        :options="optionstype"
                        text-field="text"
                        value-field="value"
                        required
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1.5">
                      <b-form-input
                        class="custom-input"
                        type="number"
                        v-model.number="row.unit_price"
                        required
                        step="1"
                        min="0"
                        max="10000000.00"
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1.5">
                      <b-form-input
                        class="custom-input"
                        :value="totalHT(row)"
                        readonly
                      />
                    </div>
                    <div class="input-wrapper mx-2" style="flex: 1">
                      <b-form-select
                        class="custom-input"
                        v-model.number="row.tva"
                        :options="optionsTVA"
                        required
                      />
                    </div>
                  </div>

                  <div class="row input-wrapper m-2">
                    <div style="flex: 0.5"></div>
                    <div class="mx-2" style="flex: 0.5">{{ $t("DESC") }}</div>
                  </div>
                  <div class="row input-wrapper m-2">
                    <div style="flex: 2"></div>
                    <div style="flex: 12">
                      <b-form-textarea
                        class="custom-input"
                        v-model="row.desc"
                        :placeholder="$t('AJUTER_DESCRIPTION')"
                        rows="5"
                      ></b-form-textarea>
                    </div>
                  </div>
                  <div class="row m-2">
                    <div style="flex: 13"></div>
                    <div style="flex: 1">
                      <b-button
                        @click="removeRow(index)"
                        class="button-pre-style custom-input"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </div>
                  </div>

                  <hr
                    v-if="
                      index <
                      getdevisMonoFiche.data.GroupeLigneDocument_produit
                        .length -
                        1
                    "
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
  </div>
</template>
<script>
import searchInput from "@/components/ui/searchInput";
import { mapActions, mapGetters,mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
export default {
  components: {
    searchInput,
  },
 created(){
  this.all_mainoeuvres({ page: this.page, perPage: 100000 });
this.all_products({ page: this.page, perPage: 100000 });
},
  data() {
    return {
      isLoading:false,
      selected: false,
      deletedIds: [],
      product_to_delete: [],
      group_to_add: [],
      form: {},
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "m3", text: this.$t("m3") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "forfait", text: this.$t("FORFAIT") },
        { value: "unitaire", text: this.$t("unitaire") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
    };
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  
    produitIds: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
    token: (state) => state.token,
  }),
    ...mapGetters([
      "getAllProducts",
      "getProductLoading",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getAllentreprises",
      "getOnlineUser",
      "getforfaitLoading",
      "getdevisMonoFiche",
    ]),
    isProductDisabled() {
      return this.getdevisMonoFiche.data?.GroupeLigneDocument_produit.some(
        (item) => item.type === "Product" || item.type_ligne === "Product"
      );
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },

  },
 methods: {
    ...mapActions([
      "updateProduct",
      "setDevisMonoFiche",
     
      "update_devis_group_BAR",
      "all_products",
      "all_mainoeuvres",
      "all_forfaits",
      "store_forfaitjson",
      "store_productJSON",
      "store_mainoeuvreJSON",
    ]),
    async putIsolationMur(payload) {
     
     try {
       const response = await axios.put(
         domain + `/isolation_murs/${payload.id}`,
         payload,
         {
           headers: {
             Authorization: `Bearer ` + this.token,
           },
         }
       );
       console.log('response',response.data.data)
return response.data.data
     } catch (error) {
   
       throw new Error("Failed to post isolation combles toiture.");
     }
 },
   
    async prod(){
     await this.all_products()
     await this.all_mainoeuvres()
    },
    async produit(type, produit) {
      let response;

      switch (type) {
        case "Forfait":
          response = await this.store_forfaitjson(produit);

          break;
        case "Main d'oeuvre":
          produit.type_cout = 0;
          response = await this.store_mainoeuvreJSON(produit);

          break;
        case "Product":
          (produit.nom = produit.lib),
            (produit.information = produit.desc),
            (response = await this.store_productJSON(produit));

          break;
        default:
          break;
      }
      return response;
    },
   
   
   
   
   
   
   
   
    async goToNextStep() {
      this.isLoading=true;
      this.group_to_add = [];
      this.cleanGroup();
      let product_to_add = [];

      for (const group of this.group_to_add) {
        await this.processGroupProducts(group, product_to_add);
      }

      const result = [
        {
          id: this.group_to_add[0].id,
          type_piece_id: 116,
          piece: "piece1",
          type_traveaux_id: 1,
          sous_traitant_id:
            this.getdevisMonoFiche?.data?.groupeLigneDocument[0]?.sous_traitant
              ?.id || null,
          traveaux: "trav1",
          product_to_delete: this.deletedIds,
          product_to_add: product_to_add,
        },
      ];

     const res= await this.update_devis_group_BAR({ result });
      const data = this.fetchProduct(
        res.data.data.GroupeLigneDocument_produit
      );
    
      if (data !== null) {
        if(this.getdevisMonoFiche.data.devis.isolation_murs == null){

this.$emit("changeStep", 7, true);
}else{
        const isolationCombles = [
          {
            ...this.getdevisMonoFiche.data.devis.isolation_murs,
            id: this.getdevisMonoFiche.data.devis.isolation_murs.id,
            marque: data.fabrication || "",
            reference: data.ref || "",
          },
        ];

  await this.putIsolationMur(isolationCombles[0]);
        } 
       
      }else{
        if(this.getdevisMonoFiche.data.devis.isolation_murs == null){

this.$emit("changeStep", 7, true);
}else{
        const isolationCombles = [
          {
            ...this.getdevisMonoFiche.data.devis.isolation_murs,
            id: this.getdevisMonoFiche.data.devis.isolation_murs.id,
            marque: "",
            reference: "",
          },
        ];
  await this.putIsolationMur(isolationCombles[0]);
        } 
      }
this.isLoading=false
      this.$emit("changeStep", 7, true);
      
    },
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
    fetchProduct(products) {
      const product = products?.find((item) => item.type_ligne === "Product");

      if (product) {
        return {
          ref: product.ref,
          fabrication: product.fabricant || null,
        };
      }

      return null;
    },
    async processGroupProducts(group, product_to_add) {
      for (const produit of group.produits) {
        if (!produit.searchInputValue || !produit.searchInputValue.id) {
          const response = await this.produit(produit.type, produit);
          produit.searchInputValue = response;
          produit.produit_id = response?.id;
        } else {
          produit.produit_id = produit.searchInputValue.id;
        }

        produit.type_ligne = produit.type;
        produit.totalHT = produit.unit_price * produit.quantite;
        // delete produit.searchInputValue;

        this.addUniqueProduct(produit, product_to_add);
      }
    },

    addUniqueProduct(produit, product_to_add) {
      const produitId = Number(produit.produit_id);
   /** Update the product in the group_ligne_document **/
   this.updateProduct(produit);
      if (!this.produitIds.includes(produitId)) {
        this.produitIds.push(produitId);

        if (produitId) {
          product_to_add.push(produit);
        }
      }
    },

    goToPrevStep() {
      this.$emit("changeStep", 5);
    },
    limitDisplay() {
      this.row.selectedItems = [...this.row.selectedItems];
    },

    addRow(type) {
      if(type==="Product"){
        this.getdevisMonoFiche.data.GroupeLigneDocument_produit.push({
        groupelignedocument_id:
          this.getdevisMonoFiche.data.groupeLigneDocument[0].id,
        type: 'Main d\'oeuvre',
        ref: "",
        lib: "",
        quantite: 1,
        type_unite: null,
        unit_price: 0,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });
      }
      this.getdevisMonoFiche.data.GroupeLigneDocument_produit.push({
        groupelignedocument_id:
          this.getdevisMonoFiche.data.groupeLigneDocument[0].id,
        type: type,
        ref: "",
        lib: "",
        quantite: 1,
        type_unite: null,
        unit_price: 0,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });
    },

    shouldDisplaySearchInput(produit) {
      return !this.produitIds.includes(produit.produit_id);
    },
    formatSelectedItems(selectedItems) {
      let displayText = selectedItems.join(", ");
      if (displayText.length > 100) {
        displayText = displayText.substring(0, 10) + "...";
      }
      return displayText;
    },

    removeRow(index) {
      const rowToRemove =
        this.getdevisMonoFiche.data?.GroupeLigneDocument_produit[index];

      const idToRemove = rowToRemove.id;

      if (idToRemove !== null) {
        this.deletedIds.push(idToRemove);

        this.getdevisMonoFiche.data?.GroupeLigneDocument_produit.splice(
          index,
          1
        );
      }

      this.$emit("deletedIds", this.deletedIds);
    },
    addProduit(selectedItem, index) {
      if (
        this.getdevisMonoFiche.data.GroupeLigneDocument_produit[index].type ===
        "Product"
      ) {
        const product = this.getAllProducts.find(
          (p) => p.id === selectedItem.id
        );
        this.$set(
          this.getdevisMonoFiche.data.GroupeLigneDocument_produit,
          index,
          {
            ...this.getdevisMonoFiche.data.GroupeLigneDocument_produit[index],
            ref: product.ref,
            lib: product.lib,
            type_ligne: product.type_ligne,
            unit_price:
              this.getdevisMonoFiche.data.groupeLigneDocument[0].unit_price ||
              product.unit_price,
            type_unite: product.type_unite,
            tva: product.tva,
            desc: product.desc,
            fabricant: product.fabricant,
            searchInputValue: product,
            totalHT: product.unit_price * product.quantite,
          }
        );
        this.selected = true;
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      } else if (
        this.getdevisMonoFiche.data.GroupeLigneDocument_produit[index].type ===
        "Forfait"
      ) {
        const forfait = this.getAllforfaits.find(
          (f) => f.id === selectedItem.id
        );

        this.$set(
          this.getdevisMonoFiche.data.GroupeLigneDocument_produit,
          index,
          {
            ...this.getdevisMonoFiche.data.GroupeLigneDocument_produit[index],
            ref: forfait.ref,
            lib: forfait.lib,
            unit_price: forfait.unit_price,
            type_unite: forfait.type_unite,
            tva: forfait.tva,
            type_ligne: forfait.type_ligne,
            desc: forfait.desc,
            searchInputValue: forfait,
            totalHT: forfait.unit_price * forfait.quantite,
          }
        );
        this.selected = true;
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      } else if (
        this.getdevisMonoFiche.data.GroupeLigneDocument_produit[index].type ===
        "Main d'oeuvre"
      ) {
        const mainoeuvre = this.getAllmainoeuvres.find(
          (m) => m.id === selectedItem.id
        );
        this.$set(
          this.getdevisMonoFiche.data.GroupeLigneDocument_produit,
          index,
          {
            ...this.getdevisMonoFiche.data.GroupeLigneDocument_produit[index],
            ref: mainoeuvre.ref,
            lib: mainoeuvre.lib,
            desc: mainoeuvre.desc,
            unit_price: mainoeuvre.unit_price,
            type_ligne: mainoeuvre.type_ligne,
            type_unite: mainoeuvre.type_unite,
            tva: mainoeuvre.tva,
            searchInputValue: mainoeuvre,
            totalHT: mainoeuvre.unit_price * mainoeuvre.quantite,
          }
        );
        this.selected = true;
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    
    },

    cleanGroup() {
      this.getdevisMonoFiche.data.groupeLigneDocument.forEach((group) => {
        let allGroupProducts = [];
        group.produits = [];
        allGroupProducts.push(...group.produits);
        this.getdevisMonoFiche.data?.GroupeLigneDocument_produit.forEach(
          (p) => {
            if (p.groupelignedocument_id == group.id) {
              group.produits.push(p);
            }
          }
        );
        this.group_to_add.push(group);
      });
    },

    totalHT(row) {
      if (row.unit_price && row.quantite) {
        return (row.unit_price * row.quantite).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style>
.truncated-label .label-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10px;
}
b-dropdown {
  background-color: #24316d !important;
}
.btn-secondary {
  color: #fff;
  background-color: #24316d !important;
  border-color: #24316d !important;
}
.header-item {
  flex: 1;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
  background-color: #24316d !important;
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}
.border {
  border: 1px solid #24316d !important;
}
.custom-input {
  box-shadow: none !important;
  width: 100%;
  height: 38px;
}

.row-item {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

.border {
  display: block;
  width: 100%;
  border: 1px solid #24316d;
  margin-top: 20px;
  overflow-x: auto;
  box-sizing: border-box;
}

.scroll-container {
  overflow-x: auto;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.content-wrapper {
  min-width: 1000px;
}

.header,
.row-item {
  display: flex;
  min-width: 100px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}
</style>
